import { graphql } from "gatsby"
import React, { useState } from "react"
import { SEO } from "shared/components"
import { FluidImage } from "shared/helpers/image_helpers"
import { LARGE_SCREEN_MEDIA_QUERY, MEDIUM_SCREEN_MEDIA_QUERY, SMALL_SCREEN_MEDIA_QUERY } from "shared/helpers/responsive_helpers"
import styled from "styled-components"
import { Layout } from "../components"

export const query = graphql`
  query {
    logo: file( relativePath: { eq: "logo.png" } ) {
      ...fluidImage
    }
  }
`

const Title1 = styled.h1`
  color: #f15f4a;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
`

const P = styled.p`
  text-transform: capitalize;
`

const TemplateGrid = styled.div`
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  padding-top: 50px;

  @media ${ SMALL_SCREEN_MEDIA_QUERY }{
    grid-template-columns: 1fr 1fr;
  }

  @media ${ MEDIUM_SCREEN_MEDIA_QUERY }{
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${ LARGE_SCREEN_MEDIA_QUERY }{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const TemplateCard = styled.a`
  background: white;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 8px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: transform 0.5s;

  img {
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
  }
`

const TemplateCardOverlay = styled.div`
  align-items: center;
  background: rgba( 0, 0, 0, 0.5 );
  border-radius: 4px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

const PreviewButton = styled.p`
  background: #f15f4a;
  border-radius: 80px;
  color: white;
  display: inline-block;
  padding: 10px 20px;
`

const TemplateCardContent = styled.div`
  padding: 20px;
  text-align: center;
`

const Section = styled.div`
  margin-top: 50px;
`

interface PageProps {
  data: {
    logo: FluidImage
  }
  pageContext: {
    errorStatus: boolean
    filteredList: [ {
      preview_url: string
      template_name: string
      thumbnail_url: string
     } ]
  }
}

const HomePage = ( { pageContext }: PageProps ) => {
  const { filteredList } = pageContext
  const { errorStatus } = pageContext

  const [ hoveredCard, setHoveredCard ] = useState( "" )

  return (
    <Layout>
    <SEO title="LocalSite Layouts - Auto Repair" description="Layout Picker for LocalSite - Auto Repair" />
    <Section>
      <Title1>LocalSite Layouts - Auto Repair</Title1>
        { !errorStatus ? (
          <TemplateGrid>
          { Object.values( filteredList ).map( ( template, i ) => (
            <TemplateCard
              key={ i }
              href={ template.preview_url }
              target="_blank"
              // tslint:disable-next-line: jsx-no-lambda
              onMouseEnter={ () => setHoveredCard( i.toString() ) }
              // tslint:disable-next-line: jsx-no-lambda
              onMouseLeave={ () => setHoveredCard( "" ) }
            >
              <img src={ template.thumbnail_url } alt=""/>
              <TemplateCardContent>
                <P>{ template.template_name }</P>
              </TemplateCardContent>
              <TemplateCardOverlay
                className={ hoveredCard === i.toString() ? "" : "hidden" }
              >
                <PreviewButton>Preview</PreviewButton>
              </TemplateCardOverlay>
            </TemplateCard>
          ) ) }
      </TemplateGrid>
      ) : ( <p className="text-center"> Oops! Looks like something went wrong! </p> ) }
    </Section>
  </Layout>
  )
}

export default HomePage
